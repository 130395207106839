<template>
  <v-app>
    <Navbar />
    <div class="fill-height" width="" v-mutate="mutation" absolute>
      <v-main style="" absolute>
        <template absolute>
          <transition name="fade" mode="out-in" style="justify-item:end;">
            <router-view absolute></router-view>
          </transition>
        </template>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import firebaseApp from "../components/firebaseInit";
firebaseApp;
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import Navbar from "./Navbar";

export default {
  name: "App",
  components: {
    Navbar,
  },
  methods: {
    mutation() {},
  },
  beforeRouteEnter(to, from, next) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        next(async (vm) => {
          await vm.$store.dispatch("binduserInfoRef");
          await vm.$store.dispatch("binduserNotificationsLists");
          await vm.$store.dispatch("bindUserUnapprove");

          if (!vm.$store.state.userInfo) {
            window.alert("Your user documents is not yet created")
          } 
        });
      } else {
        next("/Login");
      }
    });
  },
  created() {},
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
