<template>
  <v-card class="mx-0 my-0 px-0 py-0" style="position:absolute">
    <v-alert
      v-if="error"
      border="left"
      style="position:relative;top:0vh;z-index:5"
      colored-border
      color="deep-purple accent-4"
      elevation="2"
      close-text="Close Alert"
      class="deep-purple darken-4"
      dark
      dismissible
      min-width="100vw"
      @input="$store.commit('changeError', '')"
      >{{ error }}</v-alert
    >

    <v-dialog
      :fullscreen="$vuetify.breakpoint.mobile"
      v-model="mainDialogReturn"
      max-width="50vw"
      min-width="30vw"
      class=""
    >
      <v-card class="pa-3" :class="{ '': $vuetify.breakpoint.mobile }">
        <div class="tableData pa-5 pt-5 text-h6 text-center">
          <div
            class="ma-0 pa-0 text-h5"
            :class="{ 'pt-10 mb-10': $vuetify.breakpoint.mobile }"
          >
            Are You Sure You Want To Log Out
          </div>
          <v-row class="mx-10 pa-0 mt-4">
            <v-col class="text-end "
              ><v-btn
                large
                color="primary"
                class="text-h6 buttonGlass"
                @click.prevent="logOutFinal()"
                >Yes</v-btn
              ></v-col
            >
            <v-col class="text-start"
              ><v-btn
                large
                dark
                color="red"
                class="text-h6 buttonGlass"
                @click="mainDialogReturn = false"
                >No</v-btn
              ></v-col
            >
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      style="z-index:3 !important"
      v-model="drawer"
      class="pa-0"
      shaped
      app
      fixed
      floating
      :hide-overlay="true"
      :mini-variant="mini"
      :disable-resize-watcher="mini"
    >
      <v-list
        nav
        elevation="6"
        class=" .text-md-h1 ma-0"
        v-bind:class="{
          navbarDark: $vuetify.theme.dark,
          navbarLight: !$vuetify.theme.dark
        }"
        min-height="100vh"
      >
        <v-row class=" ma-0 pa-0 rounded" v-if="!mini">
          <v-col class="ma-0 pa-0">
            <div class="tableData text-center pa-0 text-center  rounded">
              <v-img
                contain
                eager
                height="24vh"
                class="ma-0 pa-0"
                src="/img/icons/1655221380799000000.png"
                alt="MZSULM"
              ></v-img>
            </div>
          </v-col>
        </v-row>

        <v-divider class="error my-2"></v-divider>

        <v-list-item
          active-class="secondary"
          ripple
          class="primary--text"
          :class="{
            buttonGrow: !$vuetify.theme.dark,
            buttonGrowDark: $vuetify.theme.dark
          }"
          link
          v-for="item in userItemList(userInfo)"
          :key="item.name"
          :to="item.link"
          elevation="1"
          replace
        >
          <v-list-item
            class="ma-0 pa-0 primary--text  buttonGrowHelper "
            :class="{ buttonGrowHelperDark: $vuetify.theme.dark }"
            style="box-sizing: border-box;"
          >
            <v-list-item-icon
              ><v-icon
                active-color="primary"
                style="padding-left:0.5rem !important"
                >{{ item.icon }}</v-icon
              ></v-list-item-icon
            >

            <v-list-item-title active-class="primary--text" align="start">{{
              item.name
            }}</v-list-item-title></v-list-item
          >
        </v-list-item>

        <v-list-item
          ripple
          link
          elevation-1
          replace
          @click.prevent="mainDialogReturn = true"
        >
          <v-list-item-icon
            ><v-icon class="">fas fa-sign-out-alt</v-icon></v-list-item-icon
          >
          <v-list-item-content>Sign Out</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      style="z-index:0 !important"
      mini
      app
      dense
      hide-on-scroll
      elevation="0"
      color="primary"
      class="icon--red"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        color="white"
      ></v-app-bar-nav-icon>
      <div
        style="display:flex;position:absolute; left:8%"
        v-if="!$vuetify.breakpoint.mobile"
      >
        <div class="white--text text-h5">
          Mizoram State Urban Livelihoods Mission
        </div>
        <div class="grey--text text-h6">&nbsp;(MzSULM)</div>
      </div>

      <v-row class="d-flex justify-end">
        <v-col md="3">
          <v-row>
            <v-col cols="" align="end">
              <v-menu
                offset-y
                transition="scale-transition"
                origin="top"
                :close-on-content-click="true"
                class
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    v-if="userNotifications"
                    color="red"
                    overlap
                    class
                    :content="userNotifications.length || 0"
                  >
                    <v-icon v-on="on" v-bind="attrs" class="mr-2" color="white">
                      fas fa-bell
                    </v-icon>
                  </v-badge>
                </template>

                <v-card>
                  <v-list
                    v-for="(noti, index) in userNotifications"
                    :key="index"
                  >
                    <v-list-item
                      class="my-n2 "
                      @click.prevent="routeToNoti(noti.id)"
                    >
                      <v-list-item-title>{{ noti.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list></v-card
                >
              </v-menu>
            </v-col>
            <v-col cols="3" xs="4" align="end">
              <v-menu
                offset-y
                transition="scale-transition"
                origin="top  right "
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="white"
                    >fas fa-cog</v-icon
                  >
                </template>
                <v-list>
                  <v-list-item dense class=" my-n2 mr-3">
                    <v-col cols="10" class="my-n3">Dark Mode</v-col>
                    <v-col>
                      <v-switch
                        color="warning"
                        @click.prevent="themeChange(dark)"
                        v-model="dark"
                      ></v-switch
                    ></v-col>
                  </v-list-item>
                  <v-list-item dense class="my-n2 mr-2">
                    <v-col cols="10">Mini Navigation</v-col>
                    <v-col>
                      <v-switch
                        color="warning"
                        @click.prevent="miniChange(mini)"
                        v-model="mini"
                      ></v-switch
                    ></v-col>
                  </v-list-item>
                </v-list> </v-menu
            ></v-col>
            <v-col cols="3" xs="4" align="end">
              <v-icon color="white" v-on:click="logOut()"
                >fas fa-sign-out-alt</v-icon
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
  </v-card>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import { mapState } from 'vuex'
import 'firebase/firestore'
export default {
  data: () => ({
    newCounter: false,
    mainDialogReturn: false,
    paginator: 1,
    userNoti: [],
    mini: null,
    dark: null,
    testArr: [],
    drawer: true,
    test:[]
  }),

  methods: {
    routeToNoti (id) {
      this.$router.replace(`/Notifications/${id}`)
    },

    userItemList (auth) {
      if (auth) {
        if (auth.nulm === 'SMMU' || auth.nulm === 'CMMU') {
          return [
            { name: 'Home', icon: 'fas fa-laptop-house', link: '/Home' },
            { name: 'User', icon: 'fas fa-user-edit', link: '/User' },

            { name: 'Reports', icon: 'fas fa-file-word', link: '/Reports' },
            {
              name: 'Notifications',
              icon: 'fas fa-envelope-open-text',
              link: '/Notifications/' + this.userNotiFirst()
            },
            { name: 'Profile', icon: 'fas fa-user-cog', link: '/Profiles' },
            {
              name: 'Send SMS',
              icon: 'fas fa-sms',
              link: '/SMSSender/SMMU_CMMU'
            }
          ]
        } else if (['SHG', 'SMA'].includes(auth.department)) {
          return [
            { name: 'Home', icon: 'fas fa-laptop-house', link: '/Home' },
            { name: 'Reports', icon: 'fas fa-file-word', link: '/Reports' },
            { name: 'Profiles', icon: 'fas fa-user-cog', link: '/Profiles' },
            {
              name: 'Notifications',
              icon: 'fas fa-envelope-open-text',
              link: '/Notifications/' + this.userNotiFirst()
            }
          ]
        } else if (['ALF', 'CLF', 'SMC'].includes(auth.department)) {
          return [
            { name: 'Home', icon: 'fas fa-laptop-house', link: '/Home' },
            { name: 'User', icon: 'fas fa-user-edit', link: '/User' },
            { name: 'Reports', icon: 'fas fas fa-file-word', link: '/Reports' },
            { name: 'Profile', icon: 'fas fa-user-cog', link: '/Profiles' },
            {
              name: 'Notifications',
              icon: 'fas fa-envelope-open-text',
              link: '/Notifications/' + this.userNotiFirst()
            }
          ]
        } else if (['ESTP', 'SEP', 'SUSV'].includes(auth.nulm)) {
          return [
            { name: 'Home', icon: 'fas fa-laptop-house', link: '/Home' },

            { name: 'Profiles', icon: 'fas fa-user-cog', link: '/Profiles' },
            {
              name: 'Notifications',
              icon: 'fas fa-envelope-open-text',
              link: '/Notifications/' + this.userNotiFirst()
            },
            {
              name: 'Upload Files',
              icon: 'fas fa-file-upload',
              link: '/uploadFile/'
            },
            {
              name: 'Help Desk',
              icon: 'fas fa-comment',
              link: '/Grievances/'
            }
          ]
        } else return {}
      }
    },
   

    async logOut () {
     
      
    },
    async logOutFinal () {
      await firebase
        .auth()
        .signOut()
        .then(async () => {
          localStorage.clear()
          window.indexedDB.deleteDatabase('firebaseLocalStorage')
          // window.indexedDB.deleteDatabase("firestore/[DEFAULT]/ud-and-pa/main");
          // var cookies = document.cookie.split(";");

          // for (var i = 0; i < cookies.length; i++) {
          //     var cookie = cookies[i];
          //     var eqPos = cookie.indexOf("=");
          //     var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          //     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          // }

          this.$router.replace('/Login')
        })
    },

    userNotiFirst () {
      if (this.userNotifications[0]) {
        return [this.userNotifications[0].id]
      } else {
        return 'nothing'
      }
    },

    darkMode () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
    themeChange (dark) {
      localStorage.setItem('dark', dark)
      this.$vuetify.theme.dark = dark
      let themeMeta = document.head.querySelector('[name~=theme-color]')

      if (dark === 'true') {
        themeMeta.setAttribute('content', '#000000')
      } else {
        themeMeta.setAttribute('content', '#4285f4')
      }
    },
    miniChange (mini) {
      localStorage.setItem('mini', mini)
    }
  },

  async mounted () {},
  beforeRouteEnter (to, from, next) {
    next(() => {})
  },
  computed: {
    mainDialogRet () {
      if (this.mainDialog) return this.mainDialog
      else return false
    },
    userHiddenList () {
      if (this.userInfo) return this.userInfo.hiddenNoti ?? []
      else return []
    },
    usernotilist () {
      let ret = this.$store.state.userNotificationsLists

      if (ret) return ret['noti']
      else return []
    },
    userArray () {
      if (this.$store.state.userInfo) {
        if (this.$store.state.userInfo.noti)
          return this.$store.state.userInfo.noti
        else return []
      } else return []
    },
    // mainDialogData: {
    //   get: function() {
    //     return this.$store.state.mainDialog ?? false;
    //   },
    //   // setter
    //   set: function() {
    //     this.$store.state.mainDialog = false;
    //   },
    // },
    ...mapState(['userInfo', 'userNotifications', 'error', 'mainDialog'])
  },

  async created () {
    localStorage.getItem('dark') == 'true'
      ? (this.$vuetify.theme.dark = this.dark = true)
      : (this.$vuetify.theme.dark = this.dark = false)
    localStorage.getItem('mini') == 'true'
      ? (this.mini = true)
      : (this.mini = false)
    this.$store.commit('changeUserPagination', 1)
  },
  watch: {
    userHiddenList () {
      if (this.newCounter) {
        firebase
      }
    },
    usernotilist (newCount) {
      this.newCounter = newCount
      let createdSecs = this.userInfo

      try {
        let arr1 = []
        if (this.newCounter.length > 0 && createdSecs) {
          this.newCounter.forEach(e => {
            // if (createdSecs.seconds < e.createdAt.seconds)
            arr1.push(e.id)
          }) ?? []
        }

        let finArr = []
        let arr2 = []
        if (this.$store.state.userInfo.hiddenNoti)
          arr2 = this.$store.state.userInfo.hiddenNoti

        if (this.$store.state.userInfo.noti) {
          arr1 = [...arr1, ...this.$store.state.userInfo.noti]
        }

        finArr = arr1.filter(o => arr2.indexOf(o) === -1)

        if (finArr.length > 10) {
          localStorage.setItem('clearNoti', `${JSON.stringify(finArr)}`)
        } else {
          localStorage.removeItem('clearNoti')
        }

        finArr.splice(10)

        this.$store.commit('changeUserNotificationsListsFinal', finArr)
        this.$store.dispatch('binduserNotifications')
      } catch (error) {
        this.$store.commit('changeError', error)
      }
    },
    userArray () {
      if (this.userArray) {
        try {
          let arr1 = []
          if (!this.newCounter) this.newCounter = []
          this.newCounter?.forEach(e => {
            arr1.push(e.id)
          }) ?? []

          let finArr = []
          let arr2 = []
          if (this.$store.state.userInfo) {
            if (this.$store.state.userInfo.hiddenNoti)
              arr2 = this.$store.state.userInfo.hiddenNoti
          }
          if (this.$store.state.userInfo) {
            if (this.$store.state.userInfo.noti)
              arr1 = [...arr1, ...this.$store.state.userInfo.noti]
          }

          finArr = arr1.filter(o => arr2.indexOf(o) === -1)

          if (finArr.length > 10) {
            localStorage.setItem('clearNoti', `${JSON.stringify(finArr)}`)
          } else {
            localStorage.removeItem('clearNoti')
          }
          finArr.splice(10)

          this.$store.commit('changeUserNotificationsListsFinal', finArr)
          this.$store.dispatch('binduserNotifications')
        } catch (error) {
          this.$store.commit('changeError', error)
        } finally {
          if (this.$store.state.userNotificationsLists.length === 0)
            this.$store.dispatch('binduserNotificationsLists')
        }
      }
    }
  }
}
</script>

<style>
.tableData {
  box-sizing: border-box;
  border: 1px solid rgba(128, 128, 128, 0.37);
  border-radius: 2px;
}
.mdi-close-circle::before {
  color: white;
  margin-right: 20px;
}
a {
  padding: 0% !important;
}
.buttonGrow::before {
  display: inline-block;
  cursor: pointer;
  width: 0%;
  height: 4%;
  min-width: 0% !important;
  min-height: 0% !important;
  top: 100% !important;
  position: absolute !important;

  left: 0% !important;
  z-index: 10;
  background: #2196f3 !important;
  opacity: 0.6 !important;
  transition: 0.5s;
}
.buttonGrow::after {
  min-width: 0% !important;
  min-height: 0% !important;
  position: absolute;
  cursor: pointer;
  width: 0%;
  min-height: 0%;
  height: 4%;
  top: 0%;

  right: 0%;
  z-index: 10;
  background: #2196f3;
  opacity: 0.6 !important;
  transition: 0.5s;
}
.buttonGrow:hover::after {
  width: 100%;
}

.buttonGrow:hover::before {
  width: 100%;
}
.buttonGrowDark::after {
  position: absolute;
  cursor: pointer;
  width: 0%;
  min-height: 0% !important;
  min-width: 0% !important;
  height: 4%;
  top: 0%;

  right: 0%;
  z-index: 10;
  background: #fff !important;
  opacity: 0.6 !important;
  transition: 0.5s;
}
.buttonGrowDark:hover::after {
  width: 100%;
}
.buttonGrowDark::before {
  cursor: pointer;
  width: 0%;
  height: 4%;
  min-height: 0% !important;
  min-width: 0% !important;
  top: 100% !important;
  position: absolute;

  z-index: 10;
  background: #fff !important;
  opacity: 0.6 !important;
  transition: 0.5s;
}

.buttonGrowDark:hover::before {
  width: 100%;
}

.buttonGrowHelper::after {
  cursor: pointer;
  width: 1%;
  height: 0%;
  min-height: 0% !important;
  min-width: 0% !important;
  top: 0%;
  left: 0%;
  position: absolute !important;
  left: 0%;
  z-index: 10;
  background: #2196f3;
  opacity: 0.6 !important;
  transition: 0.5s !important;
}
.buttonGrowHelper:hover::after {
  height: 100%;
}
.buttonGrowHelper::before {
  cursor: pointer;
  width: 1%;
  height: 0%;
  min-height: 0% !important;
  min-width: 0% !important;
  bottom: 0%;
  right: 0%;
  position: absolute !important;
  content: '';
  z-index: 10;
  background: #2196f3;
  opacity: 0.6 !important;
  transition: 0.5s !important;
}
.buttonGrowHelper:hover::before {
  height: 100%;
}
.buttonGrowHelperDark::after {
  cursor: pointer;
  width: 1%;
  height: 0%;
  min-height: 0%;
  top: 0%;
  left: 0%;
  position: absolute;
  left: 0%;
  z-index: 10;
  background: #fff;
  opacity: 0.6 !important;
  transition: 0.5s;
}
.buttonGrowHelperDark:hover::after {
  height: 100%;
}
.buttonGrowHelperDark::before {
  cursor: pointer;
  width: 1%;
  height: 0%;

  bottom: 0%;
  right: 0%;
  position: absolute;
  content: '';
  z-index: 10;
  background: #fff;
  opacity: 0.6 !important;
  transition: 0.5s;
}
.buttonGrowHelperDark:hover::before {
  height: 100%;
}
.buttonGlass {
  box-sizing: border-box;
  overflow: hidden;
}
.buttonGlass::before {
  position: absolute;
  left: 0%;
  width: 20%;
  backdrop-filter: blur(2px);
  transform: skewX(-40deg) translateX(-3rem);
  background-color: white;
  opacity: 0.4 !important;
  z-index: 2;
  transition: 0.4s ease-out;
  border-radius: none !important;
}
.buttonGlass:hover::before {
  left: 100%;
  transform: skewX(-40deg) translateX(1rem);
}
</style>
