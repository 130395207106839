var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-0 my-0 px-0 py-0",staticStyle:{"position":"absolute"}},[(_vm.error)?_c('v-alert',{staticClass:"deep-purple darken-4",staticStyle:{"position":"relative","top":"0vh","z-index":"5"},attrs:{"border":"left","colored-border":"","color":"deep-purple accent-4","elevation":"2","close-text":"Close Alert","dark":"","dismissible":"","min-width":"100vw"},on:{"input":function($event){return _vm.$store.commit('changeError', '')}}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":"50vw","min-width":"30vw"},model:{value:(_vm.mainDialogReturn),callback:function ($$v) {_vm.mainDialogReturn=$$v},expression:"mainDialogReturn"}},[_c('v-card',{staticClass:"pa-3",class:{ '': _vm.$vuetify.breakpoint.mobile }},[_c('div',{staticClass:"tableData pa-5 pt-5 text-h6 text-center"},[_c('div',{staticClass:"ma-0 pa-0 text-h5",class:{ 'pt-10 mb-10': _vm.$vuetify.breakpoint.mobile }},[_vm._v(" Are You Sure You Want To Log Out ")]),_c('v-row',{staticClass:"mx-10 pa-0 mt-4"},[_c('v-col',{staticClass:"text-end "},[_c('v-btn',{staticClass:"text-h6 buttonGlass",attrs:{"large":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.logOutFinal()}}},[_vm._v("Yes")])],1),_c('v-col',{staticClass:"text-start"},[_c('v-btn',{staticClass:"text-h6 buttonGlass",attrs:{"large":"","dark":"","color":"red"},on:{"click":function($event){_vm.mainDialogReturn = false}}},[_vm._v("No")])],1)],1)],1)])],1),_c('v-navigation-drawer',{staticClass:"pa-0",staticStyle:{"z-index":"3 !important"},attrs:{"shaped":"","app":"","fixed":"","floating":"","hide-overlay":true,"mini-variant":_vm.mini,"disable-resize-watcher":_vm.mini},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:" .text-md-h1 ma-0",class:{
        navbarDark: _vm.$vuetify.theme.dark,
        navbarLight: !_vm.$vuetify.theme.dark
      },attrs:{"nav":"","elevation":"6","min-height":"100vh"}},[(!_vm.mini)?_c('v-row',{staticClass:" ma-0 pa-0 rounded"},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('div',{staticClass:"tableData text-center pa-0 text-center  rounded"},[_c('v-img',{staticClass:"ma-0 pa-0",attrs:{"contain":"","eager":"","height":"24vh","src":"/img/icons/1655221380799000000.png","alt":"MZSULM"}})],1)])],1):_vm._e(),_c('v-divider',{staticClass:"error my-2"}),_vm._l((_vm.userItemList(_vm.userInfo)),function(item){return _c('v-list-item',{key:item.name,staticClass:"primary--text",class:{
          buttonGrow: !_vm.$vuetify.theme.dark,
          buttonGrowDark: _vm.$vuetify.theme.dark
        },attrs:{"active-class":"secondary","ripple":"","link":"","to":item.link,"elevation":"1","replace":""}},[_c('v-list-item',{staticClass:"ma-0 pa-0 primary--text  buttonGrowHelper ",class:{ buttonGrowHelperDark: _vm.$vuetify.theme.dark },staticStyle:{"box-sizing":"border-box"}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"padding-left":"0.5rem !important"},attrs:{"active-color":"primary"}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',{attrs:{"active-class":"primary--text","align":"start"}},[_vm._v(_vm._s(item.name))])],1)],1)}),_c('v-list-item',{attrs:{"ripple":"","link":"","elevation-1":"","replace":""},on:{"click":function($event){$event.preventDefault();_vm.mainDialogReturn = true}}},[_c('v-list-item-icon',[_c('v-icon',{},[_vm._v("fas fa-sign-out-alt")])],1),_c('v-list-item-content',[_vm._v("Sign Out")])],1)],2)],1),_c('v-app-bar',{staticClass:"icon--red",staticStyle:{"z-index":"0 !important"},attrs:{"mini":"","app":"","dense":"","hide-on-scroll":"","elevation":"0","color":"primary"}},[_c('v-app-bar-nav-icon',{attrs:{"color":"white"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticStyle:{"display":"flex","position":"absolute","left":"8%"}},[_c('div',{staticClass:"white--text text-h5"},[_vm._v(" Mizoram State Urban Livelihoods Mission ")]),_c('div',{staticClass:"grey--text text-h6"},[_vm._v(" (MzSULM)")])]):_vm._e(),_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{attrs:{"md":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"","align":"end"}},[_c('v-menu',{attrs:{"offset-y":"","transition":"scale-transition","origin":"top","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(_vm.userNotifications)?_c('v-badge',{attrs:{"color":"red","overlap":"","content":_vm.userNotifications.length || 0}},[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"white"}},'v-icon',attrs,false),on),[_vm._v(" fas fa-bell ")])],1):_vm._e()]}}])},[_c('v-card',_vm._l((_vm.userNotifications),function(noti,index){return _c('v-list',{key:index},[_c('v-list-item',{staticClass:"my-n2 ",on:{"click":function($event){$event.preventDefault();return _vm.routeToNoti(noti.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(noti.title))])],1)],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"3","xs":"4","align":"end"}},[_c('v-menu',{attrs:{"offset-y":"","transition":"scale-transition","origin":"top  right ","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"white"}},'v-icon',attrs,false),on),[_vm._v("fas fa-cog")])]}}])},[_c('v-list',[_c('v-list-item',{staticClass:" my-n2 mr-3",attrs:{"dense":""}},[_c('v-col',{staticClass:"my-n3",attrs:{"cols":"10"}},[_vm._v("Dark Mode")]),_c('v-col',[_c('v-switch',{attrs:{"color":"warning"},on:{"click":function($event){$event.preventDefault();return _vm.themeChange(_vm.dark)}},model:{value:(_vm.dark),callback:function ($$v) {_vm.dark=$$v},expression:"dark"}})],1)],1),_c('v-list-item',{staticClass:"my-n2 mr-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10"}},[_vm._v("Mini Navigation")]),_c('v-col',[_c('v-switch',{attrs:{"color":"warning"},on:{"click":function($event){$event.preventDefault();return _vm.miniChange(_vm.mini)}},model:{value:(_vm.mini),callback:function ($$v) {_vm.mini=$$v},expression:"mini"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3","xs":"4","align":"end"}},[_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){return _vm.logOut()}}},[_vm._v("fas fa-sign-out-alt")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }